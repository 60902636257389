/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const CheckboxOnIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path fill="none" d="M2.7 2.7h18.6v18.6H2.7z" />
		<path d="M7.8 10l-1.4 1.4 4.7 4.7L21.3 5.8l-1.4-1.4-8.9 8.8L7.8 10zm11.4 9.2H4.8V4.8h10.3V2.7H4.8c-1.1 0-2.1.9-2.1 2.1v14.5c0 1.1.9 2.1 2.1 2.1h14.5c1.1 0 2.1-.9 2.1-2.1V11h-2.1v8.2z" />
	</svg>
))
CheckboxOnIconSvg.displayName = 'CheckboxOnIconSvg'

const CheckboxOnIcon = forwardRef((props, ref) => <Icon component={CheckboxOnIconSvg} ref={ref} {...props} />)
CheckboxOnIcon.displayName = 'CheckboxOnIcon'

CheckboxOnIcon.defaultProps = {
	...Icon.defaultProps,
}
CheckboxOnIcon.propTypes = {
	...Icon.propTypes,
}

export default CheckboxOnIcon
export { CheckboxOnIconSvg }
