/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

/** this file is used by project to add new icons */
import dynamic from 'next/dynamic'
import EmptyIcon from '../icon/empty'

export * from './project-related-icons'
export const CounterIcon = dynamic(() => import('@/components/ui/atoms/icons/counter-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const CounterIconSvg = dynamic(
	() => import('@/components/ui/atoms/icons/counter-icon').then((mod) => mod.CounterIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const FlightIcon = dynamic(() => import('@/components/ui/atoms/icons/flight-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const FlightIconSvg = dynamic(
	() => import('@/components/ui/atoms/icons/flight-icon').then((mod) => mod.FlightIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
export const PersonIcon = dynamic(() => import('@/components/ui/atoms/icons/person-icon'), {
	loading: function loadingEmptyIcon() {
		return <EmptyIcon />
	},
})
export const PersonIconSvg = dynamic(
	() => import('@/components/ui/atoms/icons/person-icon').then((mod) => mod.PersonIconSvg),
	{
		loading: function loadingEmptyIcon() {
			return <EmptyIcon />
		},
	}
)
